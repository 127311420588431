<template>
  <div>
    <span
      class="link mb-2"
      @click="back"
    > Назад </span>
    <b-row
      v-if="imeiInfo"
      class="mt-2 align-items-center"
    >
      <b-col cols="3">
        <b-overlay :show="loadingBtn">
          <b-button
            variant="primary"
            class="ml-2 text-uppercase"
            @click="checkAttachStatus"
          >
            <feather-icon icon="RefreshCwIcon" />
            <span class="align-middle"> Tekshirish</span>
          </b-button>
        </b-overlay>
      </b-col>
      <b-col cols="9">
        <div class="d-flex justify-content-end align-items-center mt-1">
          <div v-if="imeiInfo.imei_1">
            <p
              class="ml-2 text-uppercase"
            >
              <b>Status 1: </b>
              <span
                class="badge badge-primary w-100"
                :class="{ 'badge-danger': (imeiInfo.status_1 == 'blocked')||(imeiInfo.status_1 == 'error') }"
              >
                <feather-icon
                  v-if="imeiInfo.status_1 =='blocked'"
                  icon="LockIcon"
                />
                {{ imeiInfo.status_1 }}
              </span>
            </p>
          </div>
          <div
            v-if="imeiInfo.imei_2"
            class="ml-2"
          >
            <p
              class="ml-2 text-uppercase"
            >
              <b>Status 2: </b>
              <span
                class="badge badge-primary w-100"
                :class="{ 'badge-danger': (imeiInfo.status_2 == 'blocked')||(imeiInfo.status_2 == 'error') }"
              >
                <feather-icon
                  v-if="imeiInfo.status_2 =='blocked'"
                  icon="LockIcon"
                />
                {{ imeiInfo.status_2 }}
              </span>
              <!-- <span class="font-weight-bolder text-info"> {{ imeiInfo.status_2 }}</span> -->

            </p>
          </div>
          <!--  -->
          <b-button
            v-if="imeiInfo.search_response_data"
            v-b-tooltip.hover.html="JSON.stringify(imeiInfo.search_response_data)"
            variant="outline-success"
            class="ml-2"
          >
            <feather-icon icon="AlertCircleIcon" />
          </b-button>
          <!--  -->
        </div>
      </b-col>
    </b-row>
    <b-overlay :show="loading">

      <b-row
        v-if="imeiInfo"
        class="mt-2"
      >
        <b-col cols="12">
          <b-card class="earnings-card">
            <div class="d-flex justify-content-between align-middle">
              <h3 class="mb-2">
                Biriktirilgan shartnoma ma'lumotlari
              </h3>
            </div>
            <div
              v-if="
                imeiInfo &&
                  imeiInfo.contract_id &&
                  imeiInfo.contract_product_id &&
                  imeiInfo.contract
              "
            >
              <p v-if="imeiInfo.contract.client">
                <b>Biriktirilgan mijoz</b>:
                <span
                  class="link"
                  @click="
                    $router.push({
                      name: 'payment-show',
                      params: { id: imeiInfo.contract.id },
                    })
                  "
                >
                  {{
                    [
                      imeiInfo.contract.client.lastname,
                      imeiInfo.contract.client.firstname,
                      imeiInfo.contract.client.middlename,
                    ].join(" ")
                  }}
                  ({{
                    imeiInfo.contract.client.doc_series +
                      imeiInfo.contract.client.doc_number +
                      " / " +
                      imeiInfo.contract.client.pin
                  }})
                </span>
              </p>
              <p>
                <b>Mijoz telefoni</b>: +998{{ imeiInfo.contract.client.phone }};
                Yaqin qarindoshlari raqamlari: +998{{
                  imeiInfo.contract.near_phone1
                }}, +998{{ imeiInfo.contract.near_phone2 }}
              </p>
              <p v-if="imeiInfo.contract">
                <b>Shartnoma summasi</b>:
                {{ imeiInfo.contract.credit_amount | formatPrice }} so'm
              </p>
              <p v-if="imeiInfo.contract_product">
                <b>Sotib olingan mahsulot</b>:
                {{ imeiInfo.contract_product.category.name_uz }}({{
                  imeiInfo.contract_product.barcode
                }})
              </p>
              <p v-if="imeiInfo.contract.contract_pdf_url">
                <b>Shartnoma</b>:
                <a
                  :href="storageUrlG + imeiInfo.contract.contract_pdf_url"
                  target="_blank"
                  class="link"
                >
                  <feather-icon icon="PaperclipIcon" />Shartnoma</a>
              </p>
              <p>
                <b>Yaratilgan sana</b>:
                {{ dateDotFormat(imeiInfo.contract.created_at) }}
              </p>
              <p>
                <b>IMEI 1</b>:
                {{ imeiInfo.imei_1 ? imeiInfo.imei_1 : 'Mavjud emas' }}
              </p>
              <p>
                <b>IMEI 2</b>:
                {{ imeiInfo.imei_2 ? imeiInfo.imei_2 : 'Mavjud emas' }}
              </p>
            </div>
            <div v-else>
              <span class="text-muted">Malumot topilmadi</span>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <b-row
        v-if="imeiInfo"
        class="mt-2"
      >

        <!-- BLOCKS -->
        <b-col
          v-if="imeiInfo.imei_1"
          cols="6"
        >
          <b-card class="earnings-card">
            <!-- <div class="d-flex"> -->
            <h3>Bloklashlar (IMEI 1: {{ imeiInfo.imei_1 }})</h3>
            <!-- </div> -->
            <b-button
              v-if="imeiInfo.status_1 == 'blocked'"
              v-can="'imei_devices.block'"
              variant="info"
              class="btn-tour-skip w-100"
              @click="openModal('unblock', 1)"
            >
              <span class="align-middle">
                <feather-icon icon="UnlockIcon" />
                {{ "Blokdan chiqarish" }}
              </span>
            </b-button>
            <b-button
              v-else-if="imeiInfo.status_1 == 'unblocked'"
              v-can="'imei_devices.unblock'"
              variant="danger"
              class="btn-tour-skip w-100"
              @click="openModal('block', 1)"
            >
              <span class="align-middle">
                <feather-icon icon="LockIcon" />
                {{ "Bloklash" }}
              </span>
            </b-button>
            <b-button
              v-else
              variant="outline-info"
              class="btn-tour-skip w-100"
            >
              <span class="align-middle">
                {{ "Ulanish jarayonida" }}
              </span>
            </b-button>
            <hr>
            <div v-if="imeiInfo && imeiInfo.blocks && imeiInfo.blocks.length">
              <div
                v-for="(item, index) in imeiInfo.blocks.filter(e => e.which_imei == 1)"
                :key="index"
                class="danger-block"
              >
                <div class="d-flex justify-content-between mb-2">
                  <b-button
                    v-b-tooltip.hover.html="item.search_response_data"
                    :variant="item.method == 'block' ? 'outline-danger' : 'outline-success'"
                    class="btn-tour-skip text-uppercase"
                  >
                    <span class="align-middle"> {{ item.method }}</span>
                  </b-button>
                  <!--  -->
                  <div
                    v-if="item.status== 'waiting'"
                    class="d-flex"
                  >
                    <b-button
                      :variant="item.status == 'waiting' ? 'outline-info' : 'outline-success'"
                      class="ml-2 btn-tour-skip text-uppercase"
                    > {{ item.status }}
                    </b-button>

                    <b-button
                      variant="primary"
                      class="btn-tour-skip text-uppercase"
                      @click="checkBlock(item.id)"
                    > Tekshirish
                    </b-button>
                  </div>
                </div>
                <p><b>Status</b>: {{ item.status }}</p>
                <p>Sana: {{ formatDateYmd(item.created_at) }}</p>
                <div>
                  Info:
                  <i v-if="item.response_data">{{
                    item.response_data.result
                      ? item.response_data.result
                      : item.response_data
                  }}</i>
                </div>
              </div>
            </div>
            <div v-else>
              Hali bloklanmagan
            </div>
          </b-card>
        </b-col>
        <!--  -->
        <b-col
          v-if="imeiInfo.imei_2"
          cols="6"
        >
          <b-card class="earnings-card">
            <!-- <div class="d-flex"> -->
            <h3>Bloklashlar (IMEI 2: {{ imeiInfo.imei_2 }})</h3>
            <!-- </div> -->
            <b-button
              v-if="imeiInfo.status_2 == 'blocked'"
              v-can="'imei_devices.block'"
              variant="info"
              class="btn-tour-skip w-100"
              @click="openModal('unblock', 2)"
            >
              <span class="align-middle">
                <feather-icon icon="UnlockIcon" />
                {{ "Blokdan chiqarish" }}
              </span>
            </b-button>
            <b-button
              v-else-if="imeiInfo.status_2 == 'unblocked'"
              v-can="'imei_devices.unblock'"
              variant="danger"
              class="btn-tour-skip w-100"
              @click="openModal('block', 2)"
            >
              <span class="align-middle">
                <feather-icon icon="LockIcon" />
                {{ "Bloklash" }}
              </span>
            </b-button>
            <b-button
              v-else
              variant="outline-info"
              class="btn-tour-skip w-100"
            >
              <span class="align-middle">
                {{ "Ulanish jarayonida" }}
              </span>
            </b-button>
            <hr>
            <div v-if="imeiInfo && imeiInfo.blocks && imeiInfo.blocks.length">
              <div
                v-for="(item, index) in imeiInfo.blocks.filter(e => e.which_imei == 2)"
                :key="index"
                class="danger-block"
              >
                <div class="d-flex justify-content-between mb-2">
                  <b-button
                    v-b-tooltip.hover.html="item.search_response_data"
                    :variant="item.method == 'block' ? 'outline-danger' : 'outline-success'"
                    class="btn-tour-skip text-uppercase"
                  >
                    <span class="align-middle"> {{ item.method }}</span>
                  </b-button>
                  <!--  -->
                  <div
                    v-if="item.status== 'waiting'"
                    class="d-flex"
                  >
                    <b-button
                      :variant="item.status == 'waiting' ? 'outline-info' : 'outline-success'"
                      class="ml-2 btn-tour-skip text-uppercase"
                    > {{ item.status }}
                    </b-button>

                    <b-button
                      variant="primary"
                      class="ml-2 btn-tour-skip text-uppercase"
                      @click="checkBlock(item.id)"
                    > Tekshirish
                    </b-button>
                  </div>
                </div>
                <p><b>Status</b>: {{ item.status }}</p>
                <p>Sana: {{ formatDateYmd(item.created_at) }}</p>
                <div>
                  Info:
                  <i v-if="item.response_data">{{
                    item.response_data.result
                      ? item.response_data.result
                      : item.response_data
                  }}</i>
                </div>
              </div>
            </div>
            <div v-else>
              Hali bloklanmagan
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>

  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { showToast } from '@/utils/toast'
import { BFormGroup, BFormInput } from 'bootstrap-vue'

export default {
  components: {
  },
  data() {
    return {
      imeiInfo: null,
      comment: '',
      contactPhone: null,
      signalModal: false,
      clickFor: 'signal',
      showPassword: false,
      loadingBtn: false,
      loading: false,
      which_imei: 1,
      fields: [
        {
          key: 'number',
          label: '№',
        },
        {
          key: 'date',
          label: 'Дата',
        },
        {
          key: 'amount',
          label: 'Сумма',
        },
      ],
    }
  },
  computed: {
    modalTitle() {
      return this.imeiInfo[`status_${this.which_imei}`] == 'unblocked'
        ? 'Bloklash'
        : 'Blokdan chiqarish'
    },
  },
  mounted() {
    this.getAction()
  },
  methods: {
    ...mapActions({
      getItem: 'imei/show',
      addBlock: 'imei/addBlock',
      unblock: 'imei/unBlock',
      check: 'imei/check',
      checkAttach: 'imei/checkAttach',
    }),
    checkAttachStatus() {
      this.loadingBtn = true
      this.checkAttach({
        imei_device_id: this.$route.params.id,
      })
        .then(res => {
          // showToast('success', this.$t('Tasdiqlandi'))
          this.getAction()
        })
        .finally(() => {
          this.loadingBtn = false
        })
    },
    async checkBlock(id) {
      this.loadingBtn = true
      await this.check({
        imei_device_block_id: id,
      })
        .then(res => {
          // showToast('success', this.$t('Tasdiqlandi'))
          this.getAction()
        })
        .finally(() => {
          this.loadingBtn = false
        })
    },
    getAction() {
      this.loading = true
      this.getItem({ id: this.$route.params.id, relations: 'contract.client|blocks|contractProduct|contractProduct.category' }).then(res => {
        this.imeiInfo = res.data
      }).finally(() => {
        this.loading = false
      })
    },
    addAction() {
      this.loadingBtn = true
      this.method()
        .then(res => {
          this.signalModal = false
          showToast('success', this.$t('Muvaffaqiyatli amalga oshirildi'))
          this.getAction()
        })
        .finally(() => {
          this.loadingBtn = false
        })
    },
    openModal(type, which = 1) {
      this.which_imei = which
      // this.clickFor = type
      this.$bvModal
        .msgBoxConfirm(type == 'block' ? 'Haqiqatdan ham qurilmani bloklamoqchimisiz' : 'Haqiqatdan ham qurilmani blokdan chiqarmoqchimisiz?', {
          title: this.$t('Подтвердите'),
          size: 'sm',
          variant: 'warning',
          okVariant: 'primary',
          okTitle: this.$t('Ha'),
          cancelTitle: this.$t('Yo`q'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.addAction()
              .then(res => {
                showToast('success', this.$t('Bloklashga yuborildi'))
                this.getItems()
              })
              // .catch(() => {
              //   showToast('success', this.$t('Успешно удалено'))
              // })
          }
        })
      // this.signalModal = true
    },
    method() {
      if (this.imeiInfo[`status_${this.which_imei}`] == 'blocked') {
        return this.unblock({
          imei_device_id: this.$route.params.id,
          which_imei: this.which_imei,
        })
      }
      return this.addBlock({
        imei_device_id: this.$route.params.id,
        which_imei: this.which_imei,
      })
    },
  },
}
</script>

<style></style>
